<template>
  <b-overlay :show="loading">
    <b-card header="Aggiungi Ispezione">
      <b-card-body>

        <validation-observer ref="rules">
          <b-form @submit.prevent="">

            <b-row>
              <b-col cols="12">

                <cs-validation
                  label="Opera"
                  rules="required"
                >
                  <template slot-scope="props">
                    <cs-select
                      id="operaId"
                      v-model="model.operaId"
                      label="Opera"
                      :state="props.errors.length === 0 ? null : false"
                      :options="opere"
                      :reduce="r => r.id"
                    />
                  </template>
                </cs-validation>

              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">

                <cs-validation
                  label="Data Ispezione"
                  rules="required"
                >
                  <template slot-scope="props">
                    <cs-input
                      id="dataIspezione"
                      v-model="model.dataIspezione"
                      type="date"
                      label="Data Ispezione"
                      :state="props.errors.length === 0 ? null : false"
                    />
                  </template>
                </cs-validation>

              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12">
                <b-form-group>
                  <cs-select
                    id="gruppi"
                    v-model="model.utenti"
                    label="Utenti"
                    :options="utenti"
                    :reduce="r => r.id"
                    multiple
                    :state="error === null ? null : false"
                    @input="userAdded"
                  />
                  <small
                    v-if="error !== null"
                    class="text-danger"
                  >{{ error }}</small>
                </b-form-group>
              </b-col>

            </b-row>

            <b-row>
              <b-col cols="12">

                <cs-select
                  id="gruppi"
                  v-model="model.gruppi"
                  label="Gruppi"
                  :options="gruppi"
                  :reduce="r => r.id"
                  :state="error === null ? null : false"
                  multiple
                  @input="userAdded"
                />

              </b-col>
            </b-row>

          </b-form>
        </validation-observer>

      </b-card-body>
      <b-card-footer>
        <cs-submit-button :click="add">
          Aggiungi
        </cs-submit-button>
      </b-card-footer>
    </b-card>
  </b-overlay>
</template>

<script>

export default {
  data() {
    return {
      loading: true,
      error: null,
      model: {
        operaId: null,
        dataIspezione: null,
        gruppi: null,
        utenti: null,
      },
      opere: [],
      gruppi: [],
      utenti: [],
    }
  },
  created() {
    this.$remote.gruppi.all()
      .then(res => {
        this.gruppi = res.data.map(a => ({ id: a.id, label: a.nome }))
      })
      .catch(err => this.showReposonseMessage(err))
      .finally(() => { this.loading = false })

    this.$remote.utenti.all()
      .then(res => {
        this.utenti = res.data.map(a => ({ id: a.id, label: a.nominativo }))
      })
      .catch(err => this.showReposonseMessage(err))
      .finally(() => { this.loading = false })

    this.$remote.opere.all()
      .then(res => {
        this.opere = res.data.map(a => ({ id: a.id, label: a.numero }))
      })
      .catch(err => this.showReposonseMessage(err))
      .finally(() => { this.loading = false })

    // if (this.$route.params.idOpera !== undefined && this.$route.params.idOpera !== null) {
    //   this.$remote.opere.single(this.$route.params.idOpera)
    //     .then(result => {
    //       this.opera = result.data
    //       this.setBreadcrumbData(this.$store, this.$route.name, { idOpera: this.$route.params.idOpera, nome: this.opera.nome })
    //     })
    //     .catch(err => {
    //       this.showResponseErrorMessage(err)
    //     })
    //   this.model.operaId = this.$route.params.idOpera
    // }
  },
  methods: {
    userAdded() {
      if ((this.model.utenti === null || this.model.utenti.length === 0)
          && (this.model.gruppi === null || this.model.gruppi.length === 0)) {
        this.error = 'Aggiungi almento un utente'
      } else {
        this.error = null
      }
    },
    add() {
      this.$refs.rules.validate()
        .then(success => {
          this.userAdded()
          if (!(success && this.error === null)) return

          this.loading = true
          this.$remote.ispezioni.create(this.model)
            .then(res => {
              if (!this.$isConcessionario) this.$routing.ISPEZIONI_DETTAGLIO.push({ id: res.data.id })
              else this.showAddSuccessMessage()
            })
            .catch(res => {
              this.showReposonseMessage(res)
            })
            .finally(() => { this.loading = false })
        })
    },
  },
}
</script>
